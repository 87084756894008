// Global styling for this template
body {
  overflow-x: hidden;
}

p {
  line-height: 1.75;
}

.page-section {
  padding: 6rem 0;
  h2.section-heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  h3.section-subheading {
    font-size: 1rem;
    font-weight: 400;
    font-family: $subheadings-font-family;
    margin-bottom: 4rem;
  }
}

@include media-breakpoint-up(md) {
  section {
    padding: 9rem 0;
  }
}
