// Button Styles

.btn-xl {
    padding: 1.25rem 2.5rem;
    font-family: $headings-font-family;
    font-size: 1.125rem;
    font-weight: $headings-font-weight;
}

.btn-social {
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 100%;
}
