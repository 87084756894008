// Override Bootstrap color system

$yellow: #fed136;
$blue: #1e68fc;

// Override Bootstrap default state colors

$primary: $blue;

// Override Bootstrap yiq lightness value

$yiq-contrasted-threshold: 205;
